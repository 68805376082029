import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import {
  Box,
  ClickAwayListener,
  Divider,
  DrawerProps,
  Fade,
  FormHelperText,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItemTextProps,
  MenuList,
  Drawer as MuiDrawer,
  Paper,
  Popper,
  Portal,
  Toolbar,
  Tooltip,
  Typography,
  lighten,
  styled,
} from "@mui/material";
import clsx from "clsx";
import {
  ReactChild,
  ReactElement,
  ReactNode,
  Ref,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { NavLinkProps, NavLink as RouterNavLink, useRouteMatch } from "react-router-dom";

export type StyleProps = {
  anchor?: "left" | "right";
  width: number | undefined;
};

export type SideBarOption = {
  name: ReactNode;
  link: string;
  icon?: ReactChild;
  classname?: string;
  absolute?: boolean;
  divider?: "top" | "bottom";
  title?: ReactNode;
  subPages?: Omit<SideBarOption, "subPages">[];
};

export type SideBarProps = {
  options: SideBarOption[];
  open?: boolean;
  onSidebarOpen?: () => void;
  onSidebarClose?: () => void;
  header?: ReactNode;
  footer?: ReactNode;
  backLink?: string;
  closedWidth?: number;
  variant?: "permanent" | "persistent" | "temporary" | undefined;
  buttonContainer?: Element;
} & StyleProps;

export const WithTooltip = ({
  component,
  open,
  title,
}: {
  component: ReactElement;
  open: boolean;
  title: ReactNode;
}) => {
  if (open) {
    return <Tooltip title={title}>{component}</Tooltip>;
  }

  return component;
};

export const classes = {
  active: `NavLink-active`,
};

export const NavLink = forwardRef((props: NavLinkProps, ref) => (
  <RouterNavLink {...props} ref={ref as unknown as Ref<HTMLAnchorElement>} />
));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  color: "inherit",
  [`&.${classes.active}`]: {
    "& > div": {
      bgcolor: lighten(theme.palette.primary.light, 0.7),
      boxShadow: `3px 0px 0px ${theme.palette.primary.main} inset`,
    },
    "& > div > div": {
      color: theme.palette.primary.main,
    },
    "& > div:hover": {
      bgcolor: lighten(theme.palette.primary.light, 0.7),
    },
  },
}));

export const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  [`&.${classes.active}`]: {
    "&": {
      bgcolor: lighten(theme.palette.primary.light, 0.7),
      boxShadow: `3px 0px 0px ${theme.palette.primary.main} inset`,
    },
    "& > div": {
      color: theme.palette.primary.main,
    },
    "&:hover": {
      bgcolor: lighten(theme.palette.primary.light, 0.7),
    },
  },
}));

export const DrawerHeader = styled("div", { shouldForwardProp: (prop) => prop !== "anchor" })<{
  anchor: "left" | "right" | undefined;
}>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const SidebarIcon = styled(ListItemIcon)(() => ({
  ".MuiListItemIcon-root": {
    minWidth: "30px",
    marginRight: "10px",
  },
}));

export const SidebarText = styled((props) => (
  <ListItemText primaryTypographyProps={{ noWrap: true }} {...props} />
))<ListItemTextProps>(() => ({
  ".MuiListItemText-primary": {
    color: "inherit",
  },
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "width" && prop !== "closedWidth",
})<DrawerProps & { width: number | undefined; closedWidth: number | undefined }>(
  ({ open, width, closedWidth }) => ({
    "& .MuiDrawer-paper": {
      width: open ? width : `${closedWidth}px`,
      overflowX: "hidden",
    },
  })
);

export const MonitoringbookPro = () => (
  <Typography variant="h6" sx={{ padding: (theme) => theme.spacing(0, 0, 0, 2) }} color="primary">
    Monitoringbook{" "}
    <Box component="span" sx={{ color: (theme) => theme.palette.text.primary }}>
      Pro
    </Box>
  </Typography>
);

const SidebarMenuItem = ({
  link,
  classname,
  name,
  subPages,
  icon,
  absolute,
  divider,
  title,
  open,
  variant,
  onSidebarClose,
  url,
  closeTabs,
  registerCloser,
}: SideBarOption & {
  url: string;
  closeTabs: (id: string) => void;
  registerCloser: (id: string, closer: Function) => void;
  onSidebarClose?: () => void;
  variant?: string;
  open?: boolean;
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const timersRef = useRef<NodeJS.Timeout[]>([]);
  const anchorRef = useRef(null);

  const match = useRouteMatch(
    [absolute ? link : `${url}${link}`].concat(
      ((subPages as Omit<SideBarOption, "subPages">[]) ?? []).map((sp) =>
        sp.absolute ? `${sp.link}` : `${url}${sp.link}`
      )
    )
  );

  useEffect(() => {
    registerCloser(link, () => {
      setMenuOpen(false);
    });
  }, [link, registerCloser]);

  const clearTimers = () => {
    timersRef.current.forEach((id) => {
      clearTimeout(id);
    });
    timersRef.current = [];
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  const button = (
    <StyledListItemButton
      ref={anchorRef}
      className={clsx(classname, match && classes.active)}
      onClick={() => {
        if (variant === "temporary" && !subPages) {
          onSidebarClose?.();
        }

        if (!menuOpen) {
          setMenuOpen(true);
        }
      }}
      onMouseEnter={() => {
        const timoutID = setTimeout(() => {
          closeTabs(link);
          setMenuOpen((prev) => (!prev ? true : prev));
        }, 300);
        timersRef.current.push(timoutID);
      }}
      onMouseLeave={() => {
        clearTimers();
      }}
    >
      <WithTooltip
        component={<SidebarIcon>{icon || <InboxIcon />}</SidebarIcon>}
        title={name}
        open={closed}
      />
      <SidebarText primary={name} />
      {subPages ? (
        <ArrowForwardIcon
          sx={{
            color: "grey.600",
          }}
        />
      ) : null}
    </StyledListItemButton>
  );

  return (
    <ClickAwayListener
      onClickAway={() => {
        handleClose();
      }}
    >
      <Box>
        {title && <FormHelperText sx={{ mx: 1, ml: 9 }}>{open ? title : " "}</FormHelperText>}
        {divider === "top" && <Divider variant={"inset"} />}
        {subPages ? (
          button
        ) : (
          <StyledNavLink
            exact
            to={absolute ? `${link}` : `${url}${link}`}
            activeClassName={classes.active}
          >
            {button}
          </StyledNavLink>
        )}
        {divider === "bottom" && <Divider variant={"inset"} />}
        {subPages ? (
          <Popper
            open={menuOpen}
            anchorEl={anchorRef.current}
            placement="right-start"
            sx={{ zIndex: 10 }}
            transition
          >
            {({ TransitionProps }) => (
              <Fade
                {...TransitionProps}
                style={{
                  transformOrigin: "top left",
                }}
              >
                <Paper>
                  <MenuList>
                    {(subPages as Omit<SideBarOption, "subPages">[]).map((subPage) => (
                      <Box key={subPage.link}>
                        {subPage.title && (
                          <FormHelperText sx={{ mx: 1, ml: 9 }}>{subPage.title}</FormHelperText>
                        )}
                        <StyledNavLink
                          to={subPage.absolute ? `${subPage.link}` : `${url}${subPage.link}`}
                          className={subPage.classname}
                          activeClassName={classes.active}
                          onClick={() => {
                            if (variant === "temporary") {
                              onSidebarClose?.();
                            }
                            handleClose();
                          }}
                        >
                          {subPage.divider === "top" && <Divider variant="inset" />}
                          <ListItemButton>
                            <WithTooltip
                              component={<SidebarIcon>{subPage.icon || <InboxIcon />}</SidebarIcon>}
                              title={subPage.name}
                              open={!open}
                            />
                            <SidebarText primary={subPage.name} />
                          </ListItemButton>
                          {subPage.divider === "bottom" && <Divider variant="inset" />}
                        </StyledNavLink>
                      </Box>
                    ))}
                  </MenuList>
                </Paper>
              </Fade>
            )}
          </Popper>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};

const SideBarV2 = ({
  options,
  footer,
  width,
  anchor,
  open,
  onSidebarOpen,
  onSidebarClose,
  header,
  closedWidth,
  variant,
  buttonContainer,
}: SideBarProps) => {
  const { url } = useRouteMatch();
  const tabClosersRef = useRef<Record<string, Function>>({});
  const registerCloser = useCallback((id: string, closer: Function) => {
    tabClosersRef.current[id] = closer;
  }, []);

  const drawerContent = (
    <>
      {variant !== "temporary" && <Toolbar sx={{ "&.MuiToolbar-root": { minHeight: "74px" } }} />}
      <DrawerHeader anchor={anchor}>
        {open ? (
          <>
            {header || <MonitoringbookPro />}
            <Box sx={{ flex: 1 }} />
            <IconButton onClick={onSidebarClose}>
              <CloseIcon />
            </IconButton>
          </>
        ) : (
          <Box sx={{ paddingLeft: (theme) => theme.spacing(0.5) }}>
            <IconButton color="inherit" onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          </Box>
        )}
      </DrawerHeader>
      <Divider />
      <List sx={{ paddingY: 0, height: "100%" }}>
        {options.map((option) => (
          <SidebarMenuItem
            key={option.link}
            url={url}
            open={open}
            variant={variant}
            onSidebarClose={onSidebarClose}
            registerCloser={registerCloser}
            closeTabs={(id: string) => {
              Object.keys(tabClosersRef.current).forEach((closerId) => {
                if (closerId !== id) {
                  tabClosersRef.current[closerId]();
                }
              });
            }}
            {...option}
          />
        ))}
      </List>
      {footer && (
        <Box
          sx={{
            paddigX: 2,
            paddingY: 1,
            boxShadow: (theme) => `3px 0px 0px ${theme.palette.primary.dark} inset`,
            justifySelf: "flex-end",
            bgcolor: "primary.light",
            color: "primary.contrastText",
          }}
        >
          {footer}
        </Box>
      )}
    </>
  );

  if (variant === "temporary") {
    return (
      <>
        {buttonContainer && (
          <Portal container={buttonContainer}>
            {open ? (
              <IconButton onClick={onSidebarClose}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton onClick={onSidebarOpen}>
                <MenuIcon />
              </IconButton>
            )}
          </Portal>
        )}
        <MuiDrawer
          anchor={anchor ?? "left"}
          variant="temporary"
          open={open}
          sx={{ zIndex: 9999 }}
          onClose={onSidebarClose}
        >
          <Box sx={{ width }}>{drawerContent}</Box>
        </MuiDrawer>
      </>
    );
  }

  return (
    <Drawer
      anchor={anchor ?? "left"}
      variant={variant ?? "permanent"}
      open={open}
      width={width}
      closedWidth={closedWidth}
    >
      {drawerContent}
    </Drawer>
  );
};

export default SideBarV2;
